.react-calendar {
  width: 100%;
  border: 0;
  font-size: 1.2em;
}

/**
 * Hide the year navigation buttons so the
 * month navigation buttons are closer to the
 * edge
 */

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__label {
  font-size: 1.4em;
}

.react-calendar__tile {
  font-size: 18px !important;

  /* default size but it's off in safari so we need to override it
  flex-basis: 14.2857%;
  */
  flex-basis: 33.33% !important;
}

.react-calendar__tile--active {
  position: relative;
  z-index: 1;
}

.rbc-calendar {
  /* Safari scrolling causes HTML elements to disappear and reappear with a delay */
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}


.rbc-background-event {
  /* Don't layer the transparencies like we did in appointments when they were overlap each other*/
  left: 0 !important;
}

.rbc-current-time-indicator::before {
  display: block;
  position: absolute;
  left: -3px;
  top: -3px;
  content: " ";
  background-color: #74ad31;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.rbc-current-time-indicator {
  z-index: 10 !important;
}

.rbc-time-header {
  display: none !important;
}

.rbc-allday-cell {
  display: none;
}
